<template>
    <div class="h-auto w-full flex flex-col">
        <loader :loading="loading" />
        
        <div v-if="mediacards" class="h-auto p-2">

            <div v-if="!selected" class="h-auto w-full rounded-lg module p-2 flex flex-col">

                <div class="h-auto py-1">
                    <span class="text-xs font-semibold" style="color: #FE694E">Media Clipping</span>
                </div>

                <div class="h-6 rounded-lg bg-body my-2">
                    <input type="text" placeholder="Search" v-model="searchInput" class="w-full h-6
                        rounded bg-body flex flex-row justify-start text-xs items-center px-2">
                </div>
                
                <div v-if="mediacards[0]" class="h-auto py-1 mt-2 flex flex-row justify-start items-center">
                    <span class="text-font-gray font-semibold text-xs"><i class="mdi mdi-star text-gold mr-1"></i>
                        {{ mediacards[0].date | moment('DD / MM / YYYY') }}
                    </span>
                </div>
                
                <div v-if="lastest.length > 0" class="h-auto rounded-lg flex flex-col">

                    <router-link :to="{ name: 'MediaMobile', params: { id: lastest[0].id } }">

                        <media-card :data="lastest[0]" view="mobile" category="lastest"/>

                    </router-link>

                </div>

                <div class="h-auto mt-4 bg-box rounded-md">

                    <div class="h-8 px-2 rounded-md flex flex-row justify-start items-center" @click="modalMoreNews = !modalMoreNews">

                        <span class="text-font-gray font-semibold text-xs">More news</span>

                        <i class="mdi text-sm font-semibold ml-auto text-font-gray" :class="{'mdi-plus': !modalMoreNews, 'mdi-close': modalMoreNews}"></i>

                    </div>

                    <div class="overflow-auto no-scrollbar px-1" :class="{'h-0': !modalMoreNews, 'h-72': modalMoreNews}" style="transition: all .3s;">

                        <router-link v-for="(el,index) in search(more_news)" :key="index" :to="{ name: 'MediaMobile', params: { id: el.id } }">
                            <media-card :data="el"  view="mobile"/>
                        </router-link>

                    </div>

                </div>

                <div class="h-auto mt-2 bg-box rounded-md">

                    <div class="h-8 px-2 rounded-md flex flex-row justify-start items-center" @click="modalCompetitors = !modalCompetitors">

                        <span class="text-font-gray font-semibold text-xs">Competitors</span>

                        <i class="mdi text-sm font-semibold ml-auto text-font-gray" :class="{'mdi-plus': !modalCompetitors, 'mdi-close': modalCompetitors}"></i>

                    </div>

                    <div class="overflow-auto no-scrollbar px-1" :class="{'h-0': !modalCompetitors, 'h-72': modalCompetitors}" style="transition: all .3s;">

                        <router-link v-for="(el,index) in search(competence)" :key="index" :to="{ name: 'MediaMobile', params: { id: el.id } }">
                            <media-card :data="el"  view="mobile"/>
                        </router-link>

                    </div>

                </div>

                <div class="h-auto mt-2 bg-box rounded-md">

                    <div class="h-8 px-2 rounded-md flex flex-row justify-start items-center" @click="modalEconomy = !modalEconomy">

                        <span class="text-font-gray font-semibold text-xs">Economy and Lifestyle</span>

                        <i class="mdi text-sm font-semibold ml-auto text-font-gray" :class="{'mdi-plus': !modalEconomy, 'mdi-close': modalEconomy}"></i>

                    </div>

                    <div class="overflow-auto no-scrollbar px-1" :class="{'h-0': !modalEconomy, 'h-72': modalEconomy}" style="transition: all .3s;">

                        <router-link v-for="(el,index) in search(economy_lifestyle)" :key="index" :to="{ name: 'MediaMobile', params: { id: el.id } }">
                            <media-card :data="el"  view="mobile"/>
                        </router-link>

                    </div>

                </div>

                <div class="h-auto mt-2">

                    <div class="h-8 w-full mt-2">
                        <div @click="go(coverPage)" class="h-full cursor-pointer rounded-md shadow bg-font-gray-15 flex flex-row
                            justify-between items-center px-2">
                            <span class="text-font-gray font-semibold text-xs">Portadas del día</span>
                            <i class="mdi mdi-open-in-new text-font-gray text-xs"></i>
                        </div>
                    </div>

                    <div class="h-8 w-full mt-2">
                        <div @click="go(histClipping)" class="h-full cursor-pointer rounded-md shadow bg-font-gray-15 flex
                            flex-row justify-between items-center px-2">
                            <span class="text-font-gray font-semibold text-xs">Hist. Clipping</span>
                            <i class="mdi mdi-open-in-new text-font-gray text-xs"></i>
                        </div>
                    </div>

                    <div class="h-8 w-full mt-2">
                        <div @click="go(newsRelease)" class="h-full cursor-pointer rounded-md shadow bg-font-gray-15 flex flex-row
                            justify-between items-center px-2">
                            <span class="text-font-gray font-semibold text-xs">Notas de prensa</span>
                            <i class="mdi mdi-open-in-new text-font-gray text-xs"></i>
                        </div>
                    </div>

                </div>

            </div>

            <div v-else class="h-96 bg-filters rounded-lg p-2 flex flex-col shadow-md">

                <!-- {{selectedEssential}} -->

                <div class="h-6 flex flex-row justify-start items-center">

                    <span class="text-font-gray font-semibold text-xxs mr-auto">{{ selectedEssential.date | moment('DD / MM / YYYY') }}</span>

                    <router-link :to="{ name: 'MediaMobile'}" class="h-6 w-6 rounded-full cursor-pointer flex flex-row justify-center items-center">
                        <i class="mdi mdi-close text-white font-semibold cursor-pointer"></i>
                    </router-link>

                </div>

                <div class="h-auto max-h-16 mt-1">
                    <span class="text-sm font-semibold truncate-3">{{ selectedEssential.title }}</span>
                </div>

                <div v-if="selectedEssential.image" class="h-24 mt-2 rounded-lg" :style="'background-image: url(' + imageRoute + selectedEssential.image + '); ' + 'background-repeat: no-repeat; background-size: cover'">                    </div>

                <div class="flex-1 min-h-0 mt-2">
                    <span class="text-font-gray text-xs" v-html="selectedEssential.body"></span>
                </div>

                <div class="h-auto flex flex-row justify-end items-center px-2">
                    <i v-show="selectedEssential.file" class="mdi text-font-gray cursor-pointer" :class="extension(selectedEssential.file)"></i>
                </div>

            </div>

        </div>

    </div>

</template>

<script>

const MediaCard = () => import('@/components/Globals/PersonalDashboard/MediaCard');
import {state} from '@/store';

export default {
    components:{
        MediaCard
    },
    data(){
        return{
            mediacards: [],
            searchInput: '',
            loading: false,
            more_news: [],
            lastest: [],
            competence: [],
            economy_lifestyle: [],
            coverPage: false,
            newsRelease: false,
            histClipping: false,
            modalMoreNews: false,
            modalCompetitors: false,
            modalEconomy: false
        }
    },
    methods: {
        openFile(file, type) {
            this.$openFile(file, type) 
        },
        extension(file) {
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        style(data) {
            let result = ''
            if (data.file) result += this.$colorByExtension(data.file)
            return result
        },
        async load() {
            try {
                this.loading = true;
                const { data } = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Media&sorts[date]=DESC&filters[active]=1', {params: state.globalParams});
                const mediaLinks = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'media_links');

                this.coverPage = mediaLinks.data.cover_page;
                this.newsRelease = mediaLinks.data.press_releases;
                this.histClipping = mediaLinks.data.hist_clipping;

                this.mediacards = data;
                this.selectMedia(this.mediaId);
                
                const { more_news, competence, lastest, economy_lifestyle  }  = this.$mediaType(this.mediacards);

                this.more_news = more_news;
                this.competence = competence;
                this.lastest = lastest;
                this.economy_lifestyle = economy_lifestyle;

            } catch (error) {
                
            } finally {
                this.loading = false;
            }

        },
        search(data) {
            return this.$search(data, this.searchInput) 
        },
        selectMedia(id) {
            if (id) {
                this.selected = this.mediacards.find(item => String(item.id) === String(id));
            } else {
                this.selected = null;
            }
        },
        go(link){
            window.open(link, '_blank') 
        },
    },
    computed: {
        imageRoute() {
            return this.$imageRoute 
        },
        mediaId() {
            return this.$route.params.id;
        },
        selected(){
            return this.$route.params.id
        },
        selectedEssential(){
            if(this.mediacards != []){
                return this.mediacards.find(element => element.id == this.selected)
            }
        }
    },
    watch: {
        mediaId(id) {
            this.selectMedia(id);
        }
    },
    mounted(){
        this.load();
    }
}
</script>